/**
 * Page type detector
 *
 * Cache the value of data-page-type in html tag so no need
 * to constantly query the DOM.
 *
 * All the boolean funcs to check page type are based
 * in fastbooking_type_by_page_type helper
 * defined in application_helper.rb
 */

if (typeof IB === 'undefined') {
  window.IB = {}
}

const htmlNode = document.querySelector('[data-page-type]')
const pageType = htmlNode && htmlNode.dataset && htmlNode.dataset.pageType

export const currentPageType = {
  get: () => pageType,
  isPresent: () => pageType !== null,
  isHome: () => pageType === 'home',
  isHotel: () => pageType === 'hotel',
  isDestinationIndex: () => pageType === 'destination-index',
  isDestination: () => pageType === 'destination',
  isSpecialtyIndex: () => pageType === 'specialty-index',
  isSpecialty: () => pageType === 'specialty',
  isHorizons: () => pageType === 'horizons',
  isOfferIndex: () => pageType === 'offer-index',
  isOffer: () => pageType === 'offer',
  isBookings: () => pageType === 'bookings',
  isGeneric: () => pageType === 'page',
  isPackage: () => pageType === 'package',
  isNeedsLoaderPage: () => pageType === 'needs-loader',
  isYouDoYou: () => pageType === 'you do you',
}

window.IB.currentPageType = currentPageType
